import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    useIntrospection,
} from "@api-platform/admin";
import {
    Login,
    Resource,
} from "react-admin"
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import MyLayout from './MyLayout';
import PackagesList from './Packages/PackagesList';
import PackagesEdit from './Packages/PackagesEdit';
import OrganizationsList from './Organizations/OrganizationsList';
import OrganizationsCreate from './Organizations/OrganizationsCreate';
import OrganizationsEdit from './Organizations/OrganizationsEdit';
import ConnectionsList from './Connections/ConnectionsList';
import ConnectionsEdit from './Connections/ConnectionsEdit';
import LogsList from './Logs/LogsList';
import UsersCreate from './Users/UsersCreate';
import UsersList from './Users/UsersList';
import UsersEdit from './Users/UsersEdit';
import FilesCreate from './Files/FilesCreate';
import ConnectionsIcon from '@material-ui/icons/SettingsEthernet';
import OrganizationsIcon from '@material-ui/icons/Business';
import UsersIcon from '@material-ui/icons/People';
import CredentialsIcon from '@material-ui/icons/Lock';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};
const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
        return { api };
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin} />
                ],
            };
        }

        throw result;
    }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if (resource !== 'posts' || !params.data.file) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */

            // Freshly dropped file are File objects and must be converted to base64 strings
        const newFile = params.data.file.filter(
                p => p.rawFile instanceof File
            );
        const formerfile = params.data.file.filter(
            p => !(p.rawFile instanceof File)
        );

        return Promise.all(newFile.map(convertFileToBase64))
            .then(base64file =>
                base64file.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewFile =>
                dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        file: [
                            ...transformedNewFile,
                            ...formerfile,
                        ],
                    },
                })
            );
    },
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

const MyLoginPage = () => (
    <Login
        // A random image that changes everyday
        backgroundImage="wallpaper.jpg"
    />
);

const App = () => (
    <HydraAdmin
        layout={MyLayout}
        dataProvider={ myDataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        loginPage={ MyLoginPage }
    >
        {permissions => [
            <Resource name="connections" list={ConnectionsList} edit={(permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_USER_PLUS')) ? ConnectionsEdit : null} icon={ConnectionsIcon} />,
            <Resource name="files" create={FilesCreate} />,
            <Resource name="logs" list={LogsList} />,
            <Resource name="users" list={permissions.includes('ROLE_ADMIN') ? UsersList : null} create={permissions.includes('ROLE_ADMIN') ? UsersCreate : null} edit={UsersEdit} icon={UsersIcon}/>,
            <Resource name="organizations" list={permissions.includes('ROLE_ADMIN') ? OrganizationsList : null} create={permissions.includes('ROLE_ADMIN') ? OrganizationsCreate : null} edit={permissions.includes('ROLE_ADMIN') ? OrganizationsEdit : null} icon={OrganizationsIcon} />,
            permissions.includes('ROLE_ADMIN')
                ?         <Resource name="packages" list={PackagesList} edit={PackagesEdit} icon={CredentialsIcon} />
                : null,
        ]}
    </HydraAdmin>
);

export default App;