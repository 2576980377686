import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput,
    ReferenceArrayInput,
    SelectArrayInput,
    BooleanInput,
    Toolbar,
    SaveButton
} from "react-admin";

const UsersEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const UsersEdit = ({ permissions, ...props }) => (
    <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<UsersEditToolbar />}>
            <TextInput source="email" />
            { permissions.includes('ROLE_ADMIN')
            ? <ReferenceArrayInput
                source="organization"
                reference="organizations"
                label="Organization"
                filterToQuery={(searchText) => ({ title: searchText })}
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            : null }
            { permissions.includes('ROLE_ADMIN')
            ? <BooleanInput label="Administrator" source="isAdmin" />
            : null }
            <PasswordInput label="Password" source="plainPassword" />
        </SimpleForm>
    </Edit>
);

export default UsersEdit;