import React from "react";
import { useState } from 'react';
import {
    List,
    EditButton,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Datagrid,
    TextField,
    Button,
    TopToolbar,
    useNotify,
    useRedirect,
    fetchStart,
    fetchEnd,
    usePermissions,
} from "react-admin";
import { useDispatch } from 'react-redux';
import { CloudUpload, Sync } from '@material-ui/icons';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () => localStorage.getItem("token") ? {
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
    "Content-type": 'application/ld+json',
} : { "Content-type": 'application/ld+json' };

const ListActions = ({ ...props}) => {
    return (
        <TopToolbar>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href='#/files/create';
                }}
                label="Upload"
            >
                <CloudUpload />
            </Button>
        </TopToolbar>
    );
};

const RunButton = ({ record }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator
        const updatedRecord = { connection: `connections/${record.originId}` };
        fetch(`${entrypoint}${record.id}/run`, { method: 'POST', headers: getHeaders(), body: JSON.stringify(updatedRecord) })
            .then(() => {
                notify('Ran connection');
                redirect('/connections');
            })
            .catch((e) => {
                notify('Error: could not run connection', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };
    if (!record.hasRunner) {
        return '';
    }
    return <Button label="Run" onClick={handleClick} disabled={loading}><Sync /></Button>;
};

const ConnectionsList = ({ ...props }) => {
    const { loading, permissions } = usePermissions()
    return loading
        ? (<div>Waiting for permissions...</div>) : (
        <List {...props} actions={<ListActions />} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="name" />
                <ReferenceField label="Organization" source="organization" reference="organizations" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                {permissions.includes('ROLE_ADMIN') &&
                    <ReferenceArrayField label="Packages" reference="packages" source="packages">
                        <SingleFieldList linkType={false}>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                }
                <RunButton/>
                {permissions.includes('ROLE_ADMIN') &&
                    <EditButton/>
                }
                {permissions.includes('ROLE_USER_PLUS') &&
                    <EditButton/>
                }
            </Datagrid>
        </List>
        );
};

export default ConnectionsList;