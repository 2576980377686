import React from "react";
import {
    Create,
    SimpleForm,
    FileInput,
    FileField,
    ReferenceInput,
    AutocompleteInput
} from "react-admin"

const FilesCreate = (props) => (
    <Create undoable={false} {...props}>
        <SimpleForm redirect="/connections">
            <ReferenceInput
                source="connection"
                reference="connections"
                label="Connections"
                filterToQuery={(searchText) => ({ title: searchText })}
                filter={{ 'exists[uploader]': true }}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <FileInput source="file" label="File">
                <FileField source="file" />
            </FileInput>
        </SimpleForm>
    </Create>
);

export default FilesCreate;