import React from "react";
import {
    Create,
    SimpleForm,
    TextInput
} from "react-admin";

const OrganizationsCreate = (props) => (
    <Create undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);

export default OrganizationsCreate;