import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    AutocompleteInput,
    usePermissions,
} from "react-admin"
import {JsonInput} from "react-admin-json-view";

const ConnectionsEdit = ({ ...props }) => {
    const {loading, permissions} = usePermissions()
    return loading
        ? (<div>Waiting for permissions...</div>) : (
            <Edit undoable={false} {...props}>
                <SimpleForm>
                    <TextInput source="name"/>
                    <ReferenceInput
                        source="organization"
                        reference="organizations"
                        label="Organization"
                        filterToQuery={(searchText) => ({title: searchText})}
                    >
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    {permissions.includes('ROLE_ADMIN') ?
                        [
                            <ReferenceArrayInput source="packages" reference="packages" allowEmpty>
                                <SelectArrayInput optionText="name"/>
                            </ReferenceArrayInput>
                        ]
                        : null
                    }
                    <JsonInput
                        source="settings"
                        jsonString={false} // Set to true if the value is a string, default: false
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: null,
                            collapsed: false,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                </SimpleForm>
            </Edit>
        );
}
export default ConnectionsEdit;