import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TopToolbar,
    CreateButton,
    useListContext,
    Button
} from "react-admin";
import React from "react";
import Connect from '@material-ui/icons/SettingsEthernet';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const ListActions = (props) => {
    const {
        basePath,
    } = useListContext();
    return (
        <TopToolbar>
            <CreateButton basePath={basePath} />
        </TopToolbar>
    );
}

const ConnectButton = ({ record }) => {
    const handleClick = () => {
        window.open(`${entrypoint}/oauth/v2/authorize/${record.originId}`);
    }

    if (record.isOAuth2) {
        return <Button label="Connect" onClick={handleClick}><Connect /></Button>;
    }
    return '';
};

const PackagesList = ({ permissions, ...props }) => (
    <List {...props} actions={<ListActions />} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid>
            <TextField source="name" />
            <ConnectButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default PackagesList;