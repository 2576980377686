import React from "react";
import {
    Create,
    BooleanInput,
    PasswordInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextInput
} from "react-admin";

const UserCreate = (props) => (
    <Create undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <ReferenceArrayInput
                source="organization"
                reference="organizations"
                label="Organization"
                filterToQuery={(searchText) => ({ title: searchText })}
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <BooleanInput label="Administrator" source="isAdmin" />
            <PasswordInput label="Password" source="plainPassword" />
        </SimpleForm>
    </Create>
);

export default UserCreate;