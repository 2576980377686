import * as React from 'react';
import { Layout, AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { forwardRef } from 'react';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to={`users/%2Fusers%2F${localStorage.getItem('id')}`}
        primaryText="Profile"
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar}/>;

export default MyLayout;