import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput
} from "react-admin"

const PackagesEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export default PackagesEdit;