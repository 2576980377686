import {
    ChipField,
    Datagrid,
    EditButton,
    List,
    BooleanField,
    ReferenceArrayField,
    SingleFieldList,
    TextField,
    TopToolbar,
    CreateButton,
    useListContext,
} from "react-admin";
import React from "react";

const ListActions = (props) => {
    const {
        basePath,
    } = useListContext();
    return (
        <TopToolbar>
            <CreateButton basePath={basePath} />
        </TopToolbar>
    );
}

const UsersList = ({ permissions, ...props }) => (
    <List {...props} actions={<ListActions />} sort={{ field: 'email', order: 'ASC' }}>
        <Datagrid>
            <TextField source="email" />
            <BooleanField label="Administrator" source="isAdmin" sortable={false} />
            <ReferenceArrayField label="Organizations" reference="organizations" source="organization" sortable={false} >
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

export default UsersList;