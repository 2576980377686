import React from "react";
import { Fragment, useState } from 'react';
import {
    List,
    Pagination,
    SelectArrayInput,
    SelectField,
    ReferenceField,
    Datagrid,
    TextField,
    DateField,
    Button,
    useNotify,
    Show,
    useRedirect,
    BulkDeleteButton,
    fetchStart,
    fetchEnd,
    usePermissions,SimpleShowLayout,
} from "react-admin";
import { useDispatch } from 'react-redux';
import { Sync} from '@material-ui/icons';
import {Typography} from "@material-ui/core";
import {JsonField} from "react-admin-json-view";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () => localStorage.getItem("token") ? {
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
    "Content-type": 'application/ld+json',
} : { "Content-type": 'application/ld+json' };

const LogsFilters = [
    <SelectArrayInput source="status" labelWidth={600} choices={[
        { id: 0, name: 'processing' },
        { id: 1, name: 'error' },
        { id: 2, name: 'processed' }
    ]} alwaysOn />,
];

const RunButton = ({ record }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator
        const updatedRecord = { log: `logs/${record.originId}` };
        fetch(`${entrypoint}${record.id}/retry`, { method: 'POST', headers: getHeaders(), body: JSON.stringify(updatedRecord) })
            .then(() => {
                notify('Retried');
                redirect('/logs');
            })
            .catch((e) => {
                notify('Error: could not retry', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };
    if (record.status !== 1) {
        return '';
    }
    return <Button label="Retry" onClick={handleClick} disabled={loading}><Sync /></Button>;
};

const LogsPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;


const LogsBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const LogShow = ( props ) => (
    <SimpleShowLayout {...props}>
        <Typography variant="h6">Message</Typography>,
        <JsonField
            render={
                record => `${props.record.message}`
            }
            addLabel={false}
            reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
            }}
        />
        {[props.record.exception !== undefined ?
            [
                <Typography variant="h6">Exceptions</Typography>,
                <JsonField
                    record={props.record.exception}
                    addLabel={false}
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: false,
                        enableClipboard: false,
                        displayDataTypes: false,
                    }}
                />
            ]
            : null]}
    </SimpleShowLayout>
);

const LogsList = ({ ...props }) => {
    const { loading, permissions } = usePermissions()
    return loading
        ? (<div>Waiting for permissions...</div>) : (
        <List {...props} actions={null} bulkActionButtons={permissions.includes('ROLE_ADMIN') && <LogsBulkActionButtons/>} filters={LogsFilters} pagination={<LogsPagination />} perPage={25} sort={{ field: 'updatedAt', order: 'DESC' }}>
            <Datagrid expand={<LogShow />}>
                <DateField label="Last updated at" source="updatedAt" showTime={true} />
                <TextField source="name" sortable={false} />
                <ReferenceField label="Connection" source="connection" sortable={false} reference="connections" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <SelectField source="status" sortable={false} choices={[
                    { id: 0, name: 'processing' },
                    { id: 1, name: 'error' },
                    { id: 2, name: 'processed' }
                ]} />
                <RunButton/>
            </Datagrid>
        </List>
        );
};

export default LogsList;