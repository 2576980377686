import {
    Datagrid,
    EditButton,
    List,
    TextField
} from "react-admin";
import React from "react";

const OrganizationsList = ({ permissions, ...props }) => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
        </Datagrid>
    </List>
);

export default OrganizationsList;